import { useEffect, useMemo, useState } from 'react'
import {
  subscribe,
  unsubscribe,
} from 'src/components/restructure/product/productEvents'
import { useCepStorage } from 'src/components/common/CepComponent/hooks/CepInformationContext'
import { FreightSimulation } from 'src/components/restructure/common/FreightSimulation'
import { FreightModal } from 'src/components/restructure/common/FreightSimulation/FreightModal'
import { useProductPageContext } from 'src/components/restructure/product/contexts/product-context'
import { ChevronRight } from 'src/components/Icons/ChevronRight'
import { maskPostalCode } from 'src/utils/masks'

export function Shipping() {
  const { addressStorage } = useCepStorage()
  const { currentSku, specificationMustBeSelected, setFreightSimulation } =
    useProductPageContext()

  const [isSimulationModalOpen, setOpenSimulationModal] = useState(false)

  useEffect(() => {
    if (specificationMustBeSelected) {
      unsubscribe('addToCart', () => setOpenSimulationModal(true))
      subscribe('addToCart', () => setOpenSimulationModal(false))
    }

    if (!addressStorage && !specificationMustBeSelected) {
      unsubscribe('addToCart', () => setOpenSimulationModal(false))
      subscribe('addToCart', () => {
        setOpenSimulationModal(true)
      })
    }

    if (addressStorage && !specificationMustBeSelected) {
      unsubscribe('addToCart', () => setOpenSimulationModal(true))
      subscribe('addToCart', () => {
        setOpenSimulationModal(false)
      })
    }
  }, [addressStorage, specificationMustBeSelected])

  const readyToGetFreightSimulation =
    specificationMustBeSelected === null && addressStorage

  const items = useMemo(
    () => [
      {
        id: currentSku.itemId,
        seller: currentSku.sellerDefault.sellerId,
        quantity: 1,
      },
    ],
    [currentSku]
  )

  useEffect(() => {
    async function runFreightSimulation() {
      const storedCEP = localStorage.getItem('cep')

      if (storedCEP) {
        await setFreightSimulation(items, storedCEP)
      }
    }

    runFreightSimulation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <section
      id="shipping"
      className="flex flex-col items-center justify-center gap-4"
    >
      {addressStorage ? (
        <button
          className="w-full text-[#101010] text-base font-medium p-4 pl-0 flex items-center justify-start gap-1"
          data-testid="openModalSimulation"
          onClick={() => setOpenSimulationModal(true)}
        >
          Entrega em
          <span className="text-[#3643BA]">
            {maskPostalCode(addressStorage?.postalCode)}
          </span>
          <ChevronRight />
        </button>
      ) : (
        <div className="w-full flex items-center flex-wrap gap-y-md justify-between border border-solid border-[#D9DDE1] p-4">
          <p className="text-sm leading-[16.8px] font-medium text-[#383838] w-full">
            Consulte os prazos de entrega e retirada
          </p>
          <button
            className="text-sm leading-[16.8px] font-medium text-restructure-action"
            data-testid="openModalSimulation"
            onClick={() => setOpenSimulationModal(true)}
          >
            Adicionar CEP
          </button>
        </div>
      )}

      {readyToGetFreightSimulation && <FreightSimulation />}

      <FreightModal
        isOpen={isSimulationModalOpen}
        setOpenModal={setOpenSimulationModal}
        items={items}
      />
    </section>
  )
}
