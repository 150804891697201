import { useContext, useState } from 'react'
import { ProductContext } from 'src/components/restructure/product/contexts'
import { Modal } from 'src/components/common/Modal'
import { CloseButtonIcon } from 'src/components/Icons/CloseButtonIcon'
import InfoGreenIcon from 'src/images/icons/InfoGreenIcon'
import LightningIcon from 'src/images/icons/LightningIcon'
import { checkEnviromentIsB2B } from 'src/utils/checkEnviroment'

export function CashbackHighlight() {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false)
  const { currentSku, product } = useContext(ProductContext)
  const {
    sellerDefault: { sellerOffer },
  } = currentSku

  const isB2B = checkEnviromentIsB2B()
  const hasCashbackHighlight = product?.flagCashbackHighlight

  return (
    <>
      {hasCashbackHighlight && sellerOffer?.cashback && !isB2B && (
        <div className="flex items-center justify-between my-4 py-1.5 px-3 bg-[#101010] md:mt-0">
          <div className="flex items-center justify-center gap-2">
            <LightningIcon />
            <p className="text-white text-xs leading-[18px] font-medium">
              Promoção Black friday
            </p>
          </div>
          <div className="flex items-center justify-center gap-2">
            <p className="text-[#7AFFA6] text-xs leading-[18px] font-medium">
              10% de Cashback
            </p>
            <button className="w-4 h-4" onClick={() => setIsTooltipOpen(true)}>
              <InfoGreenIcon />
            </button>
            <Modal
              onClose={() => setIsTooltipOpen(false)}
              isOpen={isTooltipOpen}
              className="rounded-md w-[90%] max-w-lg md:w-full md:max-w-[333px] pp:max-h-[95vh] pp:min-h-fit"
              opacityTailwind="opacity-80"
            >
              <div className="pt-10 pb-8 px-6 relative">
                <button
                  onClick={() => setIsTooltipOpen(false)}
                  className="flex items-center justify-center w-8 h-8 absolute right-2 top-2"
                >
                  <CloseButtonIcon />
                </button>
                <p className="text-center text-sm leading-[21px] text-[#101010] font-normal">
                  <span className="text-[#AB0009] font-semibold">Atenção!</span>{' '}
                  Compras feitas durante a promoção de cashback em dobro terão o
                  saldo disponível para uso a partir de
                  <span className="font-semibold"> janeiro de 2025</span>. Para
                  compras fora dessa promoção, o saldo será liberado 24 horas
                  após a emissão da Nota Fiscal.
                </p>
              </div>
            </Modal>
          </div>
        </div>
      )}
    </>
  )
}
