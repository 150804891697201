import { useContext, useState, useEffect, useRef } from 'react'
import { ProductContext } from 'src/components/restructure/product/contexts'
import { formatterPrice } from 'src/utils/formatterPrice'

import { TableInstallment } from './TableInstallment'

export function Installment() {
  const { currentSku } = useContext(ProductContext)
  const {
    sellerDefault: {
      sellerOffer: {
        installments,
        bestInstallment: bestInstallmentDefault,
        cashDiscountPercentage,
        price,
      },
    },
  } = currentSku

  const [tableOpen, setTableOpen] = useState(false)
  const containerRef = useRef<HTMLDivElement | null>(null)

  const notDecathlonCardInstallments = installments.filter(
    ({ onlyDecathlonCard }) => !onlyDecathlonCard
  )

  const bestInstallment = bestInstallmentDefault?.onlyDecathlonCard
    ? notDecathlonCardInstallments.pop()
    : bestInstallmentDefault

  const isInterestFree = !bestInstallment?.InterestRate

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setTableOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <>
      {installments.length > 0 && bestInstallment && (
        <div
          ref={containerRef}
          className="relative w-full max-w-[245px] text-sm text-secondary"
        >
          {!!cashDiscountPercentage && (
            <span>
              ou <b>{formatterPrice(price)}</b> até{' '}
            </span>
          )}
          <button
            onClick={() => setTableOpen((prev) => !prev)}
            className="underline"
            data-testid="buttonOpenTableList"
          >
            <span>
              {bestInstallment.NumberOfInstallments}x{' '}
              {!cashDiscountPercentage &&
                `de ${formatterPrice(bestInstallment.Value)}`}{' '}
              {isInterestFree && 'sem juros'}
            </span>
          </button>
          {tableOpen && <TableInstallment />}
        </div>
      )}
    </>
  )
}
