import { useContext } from 'react'
import { ProductContext } from 'src/components/restructure/product/contexts'
import { formatterPrice } from 'src/utils/formatterPrice'
import { checkEnviromentIsB2B } from 'src/utils/checkEnviroment'

import { Installment } from './Installments'

export function ProductPricing() {
  const { currentSku, product } = useContext(ProductContext)
  const {
    sellerDefault: { sellerOffer },
  } = currentSku

  const isB2B = checkEnviromentIsB2B()
  const hasCashbackHighlight = product?.flagCashbackHighlight

  const discountAmount = formatterPrice(sellerOffer.discount).replace(/\s/g, '')

  return (
    <div className="flex flex-col gap-1 restructure-small-desktop:gap-2">
      {currentSku.isAvailable && (
        <>
          <div className="flex items-center gap-2 mt-lg">
            <div className="flex flex-col gap-2">
              {sellerOffer.hasDiscount && (
                <p className="text-sm leading-[28px] font-medium text-[#616161] line-through restructure-small-desktop:leading-[21px] restructure-small-desktop:font-normal">
                  {formatterPrice(sellerOffer.listPrice)}
                </p>
              )}
              <h2 className="desktop-heading-title5 text-restructure-primary flex items-center gap-1">
                {formatterPrice(
                  sellerOffer.cashDiscountPercentage
                    ? sellerOffer.cashPrice
                    : sellerOffer.price
                )}
                {!!sellerOffer.cashDiscountPercentage && (
                  <p className="text-sm text-secondary">
                    à vista{' '}
                    <span className="text-[#006F43]">
                      ({sellerOffer.cashDiscountPercentage}% OFF)
                    </span>
                  </p>
                )}
              </h2>
            </div>

            {sellerOffer?.cashback && !hasCashbackHighlight && !isB2B && (
              <p className="desktop-overline-medium text-[10px] leading-[10px] text-[#006F43] ml-auto mr-0 restructure-small-desktop:text-[12px] restructure-small-desktop:leading-[12px]">
                {sellerOffer.cashback}
              </p>
            )}
          </div>

          {sellerOffer.hasDiscount && (
            <p className="w-fit bg-[#FFCD4E] text-xs leading-[18px] font-medium text-[#101010] p-1.5 restructure-small-desktop:text-sm restructure-small-desktop:leading-[21px]">
              -{discountAmount}
            </p>
          )}
        </>
      )}
      <Installment />
    </div>
  )
}
