import type { CartItem } from 'src/sdk/cart/validate'

import type { ProductItem } from '../../../types/productItem'
import type { Product, Items, Images } from './types'

export function generateCartItems(
  currentProduct: Product,
  selectedSku: ProductItem,
  itemsActive: Product[]
) {
  currentProduct.sku = selectedSku.itemId

  const productBuyTogether = [currentProduct, ...itemsActive]
  const itemsFinally = [] as any[]

  productBuyTogether.forEach((item) => {
    if (item?.sku) {
      const itemFinally = item.items.find(
        (itemActive: Items) => itemActive.itemId === item.sku
      )

      itemsFinally.push({ productData: item, skuData: itemFinally })
    } else {
      itemsFinally.push({ productData: item, skuData: item.items[0] })
    }
  })

  const currentSkus = itemsFinally.map((item) => ({
    product: item.productData,
    productItem: item.skuData,
  }))

  const cartItems = itemsFinally.map((currentItem) => {
    const customDataItems = currentItem.productData.items

    const listedPrice = customDataItems?.find(
      (customDataItem: Items) =>
        customDataItem.sellers[0].commertialOffer.ListPrice &&
        customDataItem.sellers[0].commertialOffer.ListPrice > 0
    )?.sellers[0].commertialOffer.ListPrice

    const currentVariation: Array<{ name: string; values: string[] }> = []

    // eslint-disable-next-line array-callback-return
    customDataItems?.map((customDataItem: Items) => {
      if (customDataItem.itemId === currentItem.skuData.itemId) {
        if (currentItem.skuData.Tamanho) {
          currentVariation.push({
            name: 'Tamanho',
            values: [currentItem.skuData.Tamanho[0]],
          })
        }

        if (currentItem.skuData.Cor) {
          currentVariation.push({
            name: 'Cor',
            values: [currentItem.skuData.Cor[0]],
          })
        }
      }
    })
    // const currentVariation = customDataItems?.find(
    //   (customDataItem: Items) =>
    //     customDataItem.itemId === currentItem.skuData.itemId
    // )?.variations

    const cartItem: CartItem = {
      productId: currentItem.productData.productId,
      id: currentItem.skuData.itemId,
      quantity: 1,
      price: currentItem.skuData.offer.price,
      listPrice: listedPrice ?? currentItem.skuData.offer.price,
      seller: {
        identifier: currentItem.skuData.sellers[0].sellerId,
      },
      itemOffered: {
        additionalProperty: [],
        brand: {
          name: currentItem.productData.brand ?? ' ',
        },
        gtin: currentItem.skuData.referenceId
          ? currentItem.skuData.referenceId[0].Value
          : '',
        image: currentItem.skuData.images.map((image: Images) => ({
          url: image.imageUrl,
          alternateName: image.imageText,
        })),
        isVariantOf: {
          productGroupID: currentItem.productData.productId,
          name: currentItem.productData.productName,
        },
        variant: currentVariation,
        name: currentItem.skuData.name,
        sku: currentItem.skuData.itemId,
        slug: currentItem.productData.linkText,
      },
    }

    return cartItem
  })

  return { cartItems, currentSkus }
}
