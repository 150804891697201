import { useProductQuantity } from './hooks/useProductQuantity'

interface ProductQuantityProps {
  quantity: number
  updateValueQuantity: (arg1: number, arg2: 'less' | 'more' | 'input') => void
}

export function ProductQuantity({
  quantity,
  updateValueQuantity,
}: ProductQuantityProps) {
  const { maxValue, minValue } = useProductQuantity()

  return (
    <div className="flex items-center justify-between w-full restructure-tablet:w-fit gap-md border border-restructure-border-secondary rounded-[100px] px-sm py-sm  ">
      <button
        aria-label="remove quantity product"
        onClick={() => updateValueQuantity(quantity, 'less')}
        className="disabled:opacity-50 disabled:cursor-not-allowed"
        disabled={quantity === minValue}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="21"
          viewBox="0 0 20 21"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.5415 10.5C3.5415 10.1548 3.82133 9.875 4.1665 9.875H15.8332C16.1783 9.875 16.4582 10.1548 16.4582 10.5C16.4582 10.8452 16.1783 11.125 15.8332 11.125H4.1665C3.82133 11.125 3.5415 10.8452 3.5415 10.5Z"
            fill="#15181B"
          />
        </svg>
      </button>
      <input
        type="number"
        aria-label="quantity of product"
        className="remove-arrow text-center desktop-body-regular-text3 text-restructure-secondary outline-none [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
        value={quantity}
        onChange={(e) => updateValueQuantity(e.target.valueAsNumber, 'input')}
        onClick={(e) => {
          ;(e.target as HTMLInputElement).select()
        }}
        min={minValue}
        max={maxValue}
      />
      <button
        aria-label="add quantity product"
        onClick={() => updateValueQuantity(quantity, 'more')}
        disabled={quantity === maxValue}
        className="disabled:opacity-50 disabled:cursor-not-allowed"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="21"
          viewBox="0 0 20 21"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 4.0415C10.3452 4.0415 10.625 4.32133 10.625 4.6665V16.3332C10.625 16.6783 10.3452 16.9582 10 16.9582C9.65482 16.9582 9.375 16.6783 9.375 16.3332V4.6665C9.375 4.32133 9.65482 4.0415 10 4.0415Z"
            fill="#15181B"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.5415 10.5C3.5415 10.1548 3.82133 9.875 4.1665 9.875H15.8332C16.1783 9.875 16.4582 10.1548 16.4582 10.5C16.4582 10.8452 16.1783 11.125 15.8332 11.125H4.1665C3.82133 11.125 3.5415 10.8452 3.5415 10.5Z"
            fill="#15181B"
          />
        </svg>
      </button>
    </div>
  )
}
