export function ResetZoomIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 3.25C23.4142 3.25 23.75 3.58579 23.75 4V10C23.75 10.4142 23.4142 10.75 23 10.75H17C16.5858 10.75 16.25 10.4142 16.25 10C16.25 9.58579 16.5858 9.25 17 9.25H22.25V4C22.25 3.58579 22.5858 3.25 23 3.25Z"
        fill="#15181B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.25 14C0.25 13.5858 0.585786 13.25 1 13.25H7C7.41421 13.25 7.75 13.5858 7.75 14C7.75 14.4142 7.41421 14.75 7 14.75H1.75V20C1.75 20.4142 1.41421 20.75 1 20.75C0.585786 20.75 0.25 20.4142 0.25 20V14Z"
        fill="#15181B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2868 4.07719C12.9481 3.68996 11.5331 3.64817 10.1738 3.95572C8.81455 4.26327 7.55536 4.91013 6.51372 5.83595C5.47208 6.76177 4.68194 7.93637 4.21704 9.25015C4.07886 9.64064 3.65029 9.84517 3.25981 9.70699C2.86932 9.56881 2.66479 9.14024 2.80297 8.74976C3.3524 7.19711 4.28619 5.80894 5.51722 4.71479C6.74825 3.62064 8.23639 2.85617 9.84278 2.4927C11.4492 2.12924 13.1214 2.17863 14.7036 2.63626C16.2819 3.09278 17.7192 3.94073 18.882 5.10127L23.5136 9.45339C23.8154 9.73703 23.8302 10.2117 23.5466 10.5135C23.2629 10.8154 22.7883 10.8302 22.4864 10.5465L17.8464 6.18652C17.8407 6.18115 17.8351 6.17569 17.8295 6.17015C16.8444 5.18447 15.6255 4.46442 14.2868 4.07719ZM0.453438 13.4864C0.737081 13.1845 1.21173 13.1697 1.51358 13.4534L6.15358 17.8134C6.1593 17.8188 6.16492 17.8242 6.17046 17.8298C7.15565 18.8154 8.37447 19.5355 9.71321 19.9227C11.0519 20.3099 12.4669 20.3517 13.8262 20.0442C15.1855 19.7366 16.4446 19.0898 17.4863 18.164C18.5279 17.2381 19.3181 16.0635 19.783 14.7498C19.9211 14.3593 20.3497 14.1547 20.7402 14.2929C21.1307 14.4311 21.3352 14.8597 21.197 15.2502C20.6476 16.8028 19.7138 18.191 18.4828 19.2851C17.2518 20.3793 15.7636 21.1437 14.1572 21.5072C12.5508 21.8707 10.8786 21.8213 9.29642 21.3636C7.71812 20.9071 6.28085 20.0592 5.11804 18.8986L0.48642 14.5465C0.18456 14.2629 0.169794 13.7882 0.453438 13.4864Z"
        fill="black"
      />
    </svg>
  )
}
