export function HideThumbsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 3C2.25 2.58579 2.58579 2.25 3 2.25H10C10.4142 2.25 10.75 2.58579 10.75 3V10C10.75 10.4142 10.4142 10.75 10 10.75H3C2.58579 10.75 2.25 10.4142 2.25 10V3ZM3.75 3.75V9.25H9.25V3.75H3.75Z"
        fill="#15181B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.25 3C13.25 2.58579 13.5858 2.25 14 2.25H21C21.4142 2.25 21.75 2.58579 21.75 3V10C21.75 10.4142 21.4142 10.75 21 10.75H14C13.5858 10.75 13.25 10.4142 13.25 10V3ZM14.75 3.75V9.25H20.25V3.75H14.75Z"
        fill="#15181B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.25 14C13.25 13.5858 13.5858 13.25 14 13.25H21C21.4142 13.25 21.75 13.5858 21.75 14V21C21.75 21.4142 21.4142 21.75 21 21.75H14C13.5858 21.75 13.25 21.4142 13.25 21V14ZM14.75 14.75V20.25H20.25V14.75H14.75Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 14C2.25 13.5858 2.58579 13.25 3 13.25H10C10.4142 13.25 10.75 13.5858 10.75 14V21C10.75 21.4142 10.4142 21.75 10 21.75H3C2.58579 21.75 2.25 21.4142 2.25 21V14ZM3.75 14.75V20.25H9.25V14.75H3.75Z"
        fill="black"
      />
    </svg>
  )
}
