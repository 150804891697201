import { useEffect, useRef } from 'react'
import Skeleton from 'react-loading-skeleton'
import { formatterPrice } from 'src/utils/formatterPrice'
import { useProductPageContext } from 'src/components/restructure/product/contexts/product-context'
import { subscribe } from 'src/components/restructure/product/productEvents'
import { InformationIcon } from 'src/components/Icons/InformationIcon'
import { checkEnviromentIsB2B } from 'src/utils/checkEnviroment'
import { EletricBolt } from 'src/components/Icons/EletricBolt'

type DeliveryTypes = Array<{
  type: string
  value: number
  shippingEstimate: string
}>

const isB2B = checkEnviromentIsB2B()

export function FreightSimulation() {
  const {
    deliveryIsAvailable,
    isLoadingFreightSimulation,
    freightSimulationValue,
  } = useProductPageContext()

  const deliveryAlertRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!deliveryIsAvailable && isB2B) {
      subscribe('addToCart', () => {
        if (!deliveryAlertRef?.current) {
          return
        }

        window.scroll({
          top: deliveryAlertRef?.current?.offsetTop - 118,
          behavior: 'smooth',
        })
      })
    }
  }, [deliveryIsAvailable])

  function formatDeliveryTpeName(deliveryType: string) {
    return deliveryType
      .replace('economic', 'Econômica')
      .replace('pick-up', 'Retire na loja')
      .replace('fast', 'Rápido')
  }

  const nextDayDeliveries = freightSimulationValue?.packages.reduce(
    (acc: DeliveryTypes, curr) => {
      const nextDayTypes = curr.deliveryTypes.filter(
        (delivery) => delivery.type === 'next-day'
      )

      return acc.concat(nextDayTypes)
    },
    []
  )

  return (
    <>
      {!isLoadingFreightSimulation && freightSimulationValue && (
        <>
          {deliveryIsAvailable &&
            nextDayDeliveries &&
            nextDayDeliveries.length > 0 && (
              <div className="flex items-center justify-between w-full p-4 border border-solid border-[#E1E4E7]">
                <div className="flex flex-col gap-1">
                  <div className="flex items-center justify-start">
                    <EletricBolt />{' '}
                    <p className="ml-1 text-[#101010] text-base font-medium">
                      Receba amanhã
                    </p>
                    <span className="flex items-center justify-center bg-[#3643BA] ml-3 text-white font-medium text-xs leading-[18px] p-1 h-[20px]">
                      Novo
                    </span>
                  </div>
                  <p className="flex flex-col text-[#616161] text-sm leading-[21px] font-medium restructure-medium-desktop:flex-row restructure-medium-desktop:gap-1.5">
                    Comprando dentro das próximas
                    <span className="text-[#DB6900]">
                      {nextDayDeliveries[0].shippingEstimate}
                    </span>
                  </p>
                </div>
                {nextDayDeliveries[0].value === 0 ? (
                  <p className="text-[#101010] text-sm leading-[16.8px] font-medium">
                    Grátis
                  </p>
                ) : (
                  <p className="text-[#101010] text-sm leading-[16.8px] font-medium">
                    {formatterPrice(nextDayDeliveries[0].value / 100)}
                  </p>
                )}
              </div>
            )}
          <div
            data-testid="resume-simulation-shipping"
            className="w-full px-4 py-2 border border-solid border-[#E1E4E7]"
          >
            {deliveryIsAvailable ? (
              <>
                {freightSimulationValue.packages.map((packageItem) => (
                  <div key={packageItem.seller}>
                    <ul className="flex flex-col gap-2">
                      {packageItem.deliveryTypes.map((deliveryType) => {
                        if (deliveryType.type === 'next-day') {
                          return <></>
                        }

                        return (
                          <li
                            className="flex items-center justify-between py-2"
                            key={deliveryType.type}
                          >
                            <div className="flex flex-col">
                              <span className="text-[#101010] text-sm leading-[16.8px] font-medium">
                                {formatDeliveryTpeName(deliveryType.type)}
                              </span>
                              <span className="text-[#616161] text-sm leading-[16.8px] font-medium">
                                Em até {deliveryType.shippingEstimate}
                              </span>
                            </div>
                            {deliveryType.value === 0 ? (
                              <p className="text-[#15181B] text-sm leading-[16.8px] font-medium">
                                Grátis
                              </p>
                            ) : (
                              <p className="text-[#15181B] text-sm leading-[16.8px] font-medium">
                                {formatterPrice(deliveryType.value / 100)}
                              </p>
                            )}
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                ))}
              </>
            ) : (
              <div
                ref={deliveryAlertRef}
                className="text-restructure-error font-inter text-xs mt-4 flex"
              >
                <InformationIcon color="#CC212A" width={24} height={24} />
                <p className="ml-1 pt-1">
                  Este produto está indisponível para este CEP. Altere ou insira
                  outro CEP para calcular o frete.
                </p>
              </div>
            )}
          </div>
        </>
      )}
      {isLoadingFreightSimulation && (
        <div className="flex flex-col gap-sm mt-md" data-testid="skeleton">
          <Skeleton className="w-full" height={24} />
          <Skeleton className="w-full" height={24} />
        </div>
      )}
    </>
  )
}
