import { useContext } from 'react'
import { ProductContext } from 'src/components/restructure/product/contexts'
import { FireIcon } from 'src/components/Icons/FireIcon'

export function LastUnitsFlag() {
  const { currentSku } = useContext(ProductContext)

  if (!currentSku?.sellerDefault?.sellerOffer?.lastUnits) {
    return null
  }

  return (
    <section className="my-6">
      <div className="flex items-center justify-center w-fit gap-1">
        <FireIcon />
        <p className="text-[#E3262F] text-sm leading-[16.8px] font-medium">
          Últimas unidades!
        </p>
      </div>
    </section>
  )
}
