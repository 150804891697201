import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ProductContext } from 'src/components/restructure/product/contexts/product-context'
import {
  makeProductDetailEvent,
  sendEvent,
} from 'src/utils/restructure/analytics'
import { publish } from 'src/components/restructure/product/productEvents'

import type {
  Variation,
  SelectedVariation,
  SkuSpecificationsName,
  ProductItem,
} from './types'
import { RenderedSelectors } from './RenderedSelectors'

import './styles.scss'

type Props = {
  slug: string
}

export function SkuSelector({ slug }: Props) {
  const { product, setCurrentSku, setSpecificationMustBeSelected, initialSku } =
    useContext(ProductContext)

  const [variations, setVariations] = useState<Variation[]>([])

  const { skuSpecifications } = useMemo(() => {
    if (product.productId === '11113') {
      const skuSpecificationsGiftCard = product.skuSpecifications
        .filter((skuSpecification) => skuSpecification.field.name === 'Valor')
        .map((specification) => {
          const valuesName = product?.items?.map(
            (sku) => sku[specification.field.name]?.[0]
          )

          return {
            ...specification,
            values: specification.values.filter((value) =>
              valuesName.includes(value.name)
            ),
          }
        })

      return { ...product, skuSpecifications: skuSpecificationsGiftCard }
    }

    return {
      ...product,
      skuSpecifications: product.skuSpecifications.map((specification) => {
        const valuesName = product?.items?.map(
          (sku) => sku[specification.field.name]?.[0]
        )

        return {
          ...specification,
          values: specification.values.filter((value) =>
            valuesName.includes(value.name)
          ),
        }
      }),
    }
  }, [product])

  const checkSkuIsAvailable = useCallback(
    ([...selectedVariation]: SelectedVariation[]) => {
      return (
        product.items.find((productItem) =>
          selectedVariation.every(
            (variation) =>
              productItem[variation.field]?.[0] === variation.value &&
              productItem.isAvailable
          )
        ) !== undefined
      )
    },
    [product.items]
  )

  const initialVariations = useMemo(
    () =>
      skuSpecifications.map((variation, idx) => {
        return {
          ...variation,
          selected: idx === 0 ? true : variation.values.length === 1,
          values: variation.values.map((variationValues) => ({
            ...variationValues,
            selected:
              idx === 0
                ? variationValues.name === initialSku[variation.field.name]?.[0]
                : variation.values.length === 1,
            isAvailable: checkSkuIsAvailable([
              { field: variation.field.name, value: variationValues.name },
            ]),
          })),
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkSkuIsAvailable, skuSpecifications]
  )

  const updateVariationsBySelectedValues = useCallback(
    (
      tempVariations: Variation[],
      selectedVariations: SelectedVariation[],
      nextVariation?: Variation
    ) => {
      const nextOriginalVariation = initialVariations.find(
        (variationItem) =>
          variationItem.field.name === nextVariation?.field.name
      )

      const itemsBySelectedVariations = product.items.filter((item) =>
        selectedVariations.every(
          (selectedVariation) =>
            item[selectedVariation.field]?.[0] === selectedVariation.value
        )
      )

      const possibleValuesBySelectedVariations =
        nextOriginalVariation?.values.filter((variationValue) =>
          itemsBySelectedVariations.find(
            (productItem) =>
              productItem[nextOriginalVariation.field.name]?.[0] ===
              variationValue.name
          )
        )

      const newNextVariation = {
        ...nextOriginalVariation,
        values: possibleValuesBySelectedVariations,
      }

      return tempVariations.map((variation) => {
        if (variation.field.name !== nextVariation?.field.name) {
          return { ...variation }
        }

        if (newNextVariation?.values?.length) {
          return {
            ...variation,
            selected: newNextVariation.values.length === 1,
            values: newNextVariation.values.map((variationValues) => ({
              ...variationValues,
              selected: newNextVariation?.values?.length === 1,
              isAvailable: checkSkuIsAvailable([
                ...selectedVariations,
                { field: variation.field.name, value: variationValues.name },
              ]),
            })),
          }
        }

        return { ...variation }
      })
    },
    [checkSkuIsAvailable, initialVariations, product.items]
  )

  const getSelectedValues = useCallback((specifications: Variation[]) => {
    return specifications
      .filter((variation) => variation.selected)
      .map((variation) => ({
        field: variation.field.name,
        value: variation.values.find((value) => value.selected)?.name as string,
      }))
  }, [])

  useEffect(() => {
    if (!initialVariations) {
      return
    }

    const nextVariant = initialVariations.find(
      (variation) => !variation.selected
    )

    const selectedValues = getSelectedValues(initialVariations)

    const validatedVariations = updateVariationsBySelectedValues(
      initialVariations,
      selectedValues,
      nextVariant
    )

    setVariations(validatedVariations)
  }, [getSelectedValues, initialVariations, updateVariationsBySelectedValues])

  const getProductByCurrentVariation = (
    productItemName: SkuSpecificationsName,
    value: string
  ) => {
    const productByCurrentVariation =
      product.items.find(
        (productItem) =>
          productItem[productItemName]?.[0] === value && productItem.isAvailable
      ) ??
      (product.items.find(
        (productItem) =>
          productItem[productItemName]?.[0] === value &&
          !productItem.isAvailable
      ) as ProductItem)

    setCurrentSku(productByCurrentVariation)
  }

  const onClickVariation = (name: SkuSpecificationsName, value: string) => {
    publish('selectVariation')
    publish('hideSelectorNotification')

    const idxCurrentVariation = variations.findIndex(
      (variation) => variation.field.name === name
    )

    const nextVariant = initialVariations[idxCurrentVariation + 1]

    const updatedVariations = variations.map((vari, index) => {
      if (idxCurrentVariation === 0) {
        getProductByCurrentVariation(name, value)
      }

      if (idxCurrentVariation === index) {
        return {
          ...vari,
          selected: true,
          values: vari.values.map((item) => ({
            ...item,
            selected: item.name === value,
          })),
        }
      }

      if (idxCurrentVariation < index) {
        return {
          ...vari,
          selected: false,
          values: vari.values.map((item) => ({ ...item, selected: false })),
        }
      }

      if (idxCurrentVariation > index) {
        return { ...vari }
      }

      return { ...vari }
    })

    const selectedValues = getSelectedValues(updatedVariations)

    const validatedVariations = updateVariationsBySelectedValues(
      updatedVariations,
      selectedValues,
      nextVariant
    )

    if (!nextVariant || nextVariant.selected) {
      try {
        const selectedProduct =
          findProductItemBySelectedVariations(validatedVariations)

        if (selectedProduct) {
          sendEventToChangeSelectedProduct(selectedProduct)
        }
      } catch {
        console.info('')
      }
    }

    setVariations(validatedVariations)
  }

  const sendEventToChangeSelectedProduct = useCallback(
    (currentSku: ProductItem) => {
      const event = makeProductDetailEvent(product, currentSku, 'skuSelector')

      sendEvent(event)
    },
    [product]
  )

  const findProductItemBySelectedVariations = useCallback(
    (selectedVariations: Variation[]) => {
      return (
        product.items.find((item) =>
          selectedVariations.every(
            (variation) =>
              item[variation.field.name]?.[0] ===
                variation.values.find((value) => value.selected)?.name &&
              item.isAvailable
          )
        ) ??
        product.items.find((item) =>
          selectedVariations.every(
            (variation) =>
              item[variation.field.name]?.[0] ===
              variation.values.find((value) => value.selected)?.name
          )
        )
      )
    },
    [product.items]
  )

  useEffect(() => {
    if (!variations.length) {
      setSpecificationMustBeSelected(null)

      return
    }

    const unselectedVariation = variations.find(
      (variation) => variation.selected === false
    )

    if (unselectedVariation) {
      setSpecificationMustBeSelected(unselectedVariation)

      return
    }

    const productBySelectedVariations =
      findProductItemBySelectedVariations(variations)

    if (productBySelectedVariations) {
      setCurrentSku(productBySelectedVariations)
      setSpecificationMustBeSelected(null)
    }

    if (!productBySelectedVariations) {
      console.error({ productId: product.productId, variations })
    }
  }, [
    setCurrentSku,
    setSpecificationMustBeSelected,
    product,
    variations,
    findProductItemBySelectedVariations,
  ])

  return (
    <div className="flex flex-col gap-[24px] pt-4 border-t border-gray">
      <RenderedSelectors
        variations={variations}
        onClickVariation={onClickVariation}
        slug={slug}
      />
    </div>
  )
}
