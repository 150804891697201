export function ZoomOutIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 3.75C6.99594 3.75 3.75 6.99594 3.75 11C3.75 15.0041 6.99594 18.25 11 18.25C15.0041 18.25 18.25 15.0041 18.25 11C18.25 6.99594 15.0041 3.75 11 3.75ZM2.25 11C2.25 6.16751 6.16751 2.25 11 2.25C15.8325 2.25 19.75 6.16751 19.75 11C19.75 15.8325 15.8325 19.75 11 19.75C6.16751 19.75 2.25 15.8325 2.25 11Z"
        fill="#15181B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1197 16.1197C16.4126 15.8268 16.8874 15.8268 17.1803 16.1197L21.5303 20.4697C21.8232 20.7626 21.8232 21.2375 21.5303 21.5304C21.2374 21.8232 20.7626 21.8232 20.4697 21.5304L16.1197 17.1804C15.8268 16.8875 15.8268 16.4126 16.1197 16.1197Z"
        fill="#15181B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.25 11C7.25 10.5858 7.58579 10.25 8 10.25H14C14.4142 10.25 14.75 10.5858 14.75 11C14.75 11.4142 14.4142 11.75 14 11.75H8C7.58579 11.75 7.25 11.4142 7.25 11Z"
        fill="black"
      />
    </svg>
  )
}
