const InfoGreenIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <g clipPath="url(#clip0_831_6670)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00004 1.83337C4.59428 1.83337 1.83337 4.59428 1.83337 8.00004C1.83337 11.4058 4.59428 14.1667 8.00004 14.1667C11.4058 14.1667 14.1667 11.4058 14.1667 8.00004C14.1667 4.59428 11.4058 1.83337 8.00004 1.83337ZM0.833374 8.00004C0.833374 4.042 4.042 0.833374 8.00004 0.833374C11.9581 0.833374 15.1667 4.042 15.1667 8.00004C15.1667 11.9581 11.9581 15.1667 8.00004 15.1667C4.042 15.1667 0.833374 11.9581 0.833374 8.00004Z"
        fill="#7AFFA6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 7.5C8.27614 7.5 8.5 7.72386 8.5 8V10.6667C8.5 10.9428 8.27614 11.1667 8 11.1667C7.72386 11.1667 7.5 10.9428 7.5 10.6667V8C7.5 7.72386 7.72386 7.5 8 7.5Z"
        fill="#7AFFA6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 5.33337C7.5 5.05723 7.72386 4.83337 8 4.83337H8.00667C8.28281 4.83337 8.50667 5.05723 8.50667 5.33337C8.50667 5.60952 8.28281 5.83337 8.00667 5.83337H8C7.72386 5.83337 7.5 5.60952 7.5 5.33337Z"
        fill="#7AFFA6"
      />
    </g>
    <defs>
      <clipPath id="clip0_831_6670">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default InfoGreenIcon
