export function FireIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g id="fire" clipPath="url(#clip0_747_31362)">
        <path
          id="Vector"
          d="M8.00006 15.3333C9.32615 15.3333 10.5979 14.8065 11.5356 13.8689C12.4733 12.9312 13.0001 11.6594 13.0001 10.3333C13.0001 9.756 12.8467 9.202 12.6667 8.68667C11.5554 9.78467 10.7114 10.3333 10.1334 10.3333C12.7967 5.66667 11.3334 3.66667 7.3334 1C7.66673 4.33333 5.4694 5.84933 4.57473 6.69133C3.84495 7.37779 3.33822 8.26765 3.12025 9.24555C2.90228 10.2235 2.98312 11.2443 3.35229 12.1757C3.72146 13.1071 4.36194 13.9061 5.19067 14.4691C6.0194 15.0322 6.99816 15.3332 8.00006 15.3333ZM8.4734 3.49C10.6341 5.32333 10.6447 6.748 8.9754 9.67267C8.46806 10.5613 9.11006 11.6667 10.1334 11.6667C10.5921 11.6667 11.0561 11.5333 11.5461 11.27C11.4008 11.8187 11.1302 12.3261 10.7555 12.7524C10.3808 13.1787 9.91227 13.5122 9.38678 13.7266C8.86129 13.941 8.2932 14.0305 7.72723 13.9881C7.16127 13.9456 6.61289 13.7724 6.12527 13.4819C5.63764 13.1915 5.2241 12.7919 4.91719 12.3145C4.61027 11.8371 4.41838 11.2949 4.35661 10.7307C4.29484 10.1665 4.36488 9.59573 4.56122 9.06322C4.75756 8.53071 5.07483 8.05105 5.48806 7.662C5.57206 7.58333 5.99806 7.20533 6.01673 7.18867C6.2994 6.93533 6.53206 6.71067 6.76206 6.46467C7.58206 5.586 8.1714 4.61133 8.47273 3.49H8.4734Z"
          fill="#E3262F"
        />
      </g>
      <defs>
        <clipPath id="clip0_747_31362">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
