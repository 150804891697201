import { CardReview } from './CardReview'
import type { ReviewItemList } from '../../types/review'

type Props = {
  reviewList: ReviewItemList[]
}

export function ReviewsList({ reviewList }: Props) {
  return (
    <ul
      aria-label="Lista com todas avaliações do produto"
      className="gap-y-4 flex flex-col overflow-y-auto"
    >
      {reviewList?.map((review) => {
        const body = review?.body
        const note = review?.note
        const firstname = review?.firstname
        const countryLabel = review?.countryLabel
        const language = review?.language
        const rangeAge = review?.rangeAge

        const reviewDescription = body
        const reviewStars = note
        const reviewUser = {
          name: firstname,
          country: countryLabel,
          years: rangeAge ? rangeAge?.split('_')?.[0] : '',
        }

        return (
          <li key={review?.id}>
            <CardReview
              description={reviewDescription}
              stars={reviewStars}
              user={reviewUser}
              language={language}
            />
          </li>
        )
      })}
    </ul>
  )
}
