import { FullScreenIcon } from 'src/components/Icons/Controls/FullScreen'
import { HideThumbsIcon } from 'src/components/Icons/Controls/HideThumbs'
import { ResetZoomIcon } from 'src/components/Icons/Controls/ResetZoom'
import { ZoomInIcon } from 'src/components/Icons/Controls/ZoomIn'
import { ZoomOutIcon } from 'src/components/Icons/Controls/ZoomOut'

interface Props {
  zoomIn: () => void
  zoomOut: () => void
  resetTransform: () => void
  toggleThumbs: () => void
  toggleFullscreen: () => void
  isFullscreen: boolean
}

export const Controls = (props: Props) => {
  return (
    <>
      <div className="botoes flex justify-center w-full gap-2">
        <button
          className="p-4 hidden restructure-tablet:block"
          aria-label="Botão de aumentar o zoom da imagem"
          onClick={() => props.zoomIn()}
        >
          <ZoomInIcon />
        </button>
        <button
          className="p-4 hidden restructure-tablet:block"
          aria-label="Botão de diminuir o zoom da imagem"
          onClick={() => props.zoomOut()}
        >
          <ZoomOutIcon />
        </button>
        <button
          className="p-4 hidden restructure-tablet:block"
          aria-label="Botão de resetar para zero o zoom da imagem"
          onClick={() => props.resetTransform()}
        >
          <ResetZoomIcon />
        </button>

        <button
          className="p-4 hidden restructure-tablet:block"
          aria-label="Botão de tela cheia da Galeria Full View"
          onClick={() => props.toggleFullscreen()}
        >
          <FullScreenIcon />
        </button>
        <button
          className="p-4"
          aria-label="Botão de ocultar as miniaturas das imagens"
          onClick={() => props.toggleThumbs()}
        >
          <HideThumbsIcon />
        </button>
      </div>
    </>
  )
}
