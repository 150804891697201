import { useContext } from 'react'
import { ProductContext } from 'src/components/restructure/product/contexts'
import type { Installment } from 'src/components/restructure/product/types/productItem'
import { formatterPrice } from 'src/utils/formatterPrice'

export function TableInstallment() {
  const { currentSku } = useContext(ProductContext)
  const {
    sellerDefault: {
      sellerOffer: { installments, price, cashDiscountPercentage },
    },
  } = currentSku

  const anyCreditCard = installments.filter(
    ({ onlyDecathlonCard }) => !onlyDecathlonCard
  )

  const decathlonCreditCard = installments.filter(
    ({ onlyDecathlonCard }) => onlyDecathlonCard
  )

  const InstallmentLine = ({
    NumberOfInstallments,
    Value,
    InterestRate,
  }: Installment) => {
    const isInterestFree = !InterestRate

    return (
      <li className="flex items-center gap-1.5 desktop-body-regular-text3 text-restructure-secondary border-gray border-b last:border-b-0 py-2">
        <span>{`${NumberOfInstallments}x ${formatterPrice(Value)}`}</span>
        {NumberOfInstallments === 1 && Value < price && (
          <span className="text-[#006F43] text-xs">
            ({cashDiscountPercentage}% OFF)
          </span>
        )}
        {isInterestFree && (
          <span className="flex flex-grow justify-end text-xs text-secondarydarkest font-bold">
            Sem juros
          </span>
        )}
      </li>
    )
  }

  return (
    <div
      className="absolute top-6 left-0 shadow-dropCard rounded-sm z-10 w-full max-w-[265px] min-w-max bg-white"
      data-testid="modalTableInstallment"
    >
      <ul className="flex flex-col px-4 py-2">
        {anyCreditCard.map((installment, i) => (
          <InstallmentLine key={i} {...installment} />
        ))}
      </ul>

      {decathlonCreditCard?.length > 0 && (
        <div className="p-sm bg-restructure-background-secondary">
          <p className="desktop-body-semibold-text3 text-restructure-action">
            Com o cartão Decathlon
          </p>
          <ul className="flex flex-col mt-xs">
            {decathlonCreditCard.map((installment, i) => (
              <InstallmentLine key={i} {...installment} />
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}
