import { useCallback } from 'react'

import type { LinxMetaProduct } from '../types'
import { useLinxEventDispatch } from './useLinxEventDispatch'

type ProductViewProps = {
  productId: string
  productPrice?: number
}

export const useLinxProductView = () => {
  const { dispatchLinxEvent } = useLinxEventDispatch()

  const sendProductViewEvent = useCallback(
    ({ productId, productPrice }: ProductViewProps) => {
      const linxMetaProduct: LinxMetaProduct = {
        page: 'product',
        pid: productId,
        price: productPrice,
      }

      if (!productPrice) {
        delete linxMetaProduct.price
      }

      return dispatchLinxEvent(linxMetaProduct)
    },
    [dispatchLinxEvent]
  )

  return { sendProductViewEvent }
}
