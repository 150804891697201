import { useContext } from 'react'
import { ProductContext } from 'src/components/restructure/product/contexts'
import type { Flag } from 'src/components/restructure/product/types/productItem'
import { checkEnviromentIsB2B } from 'src/utils/checkEnviroment'

export const GalleryFlags = () => {
  const { flags } = useContext(ProductContext).currentSku
  const isB2B = checkEnviromentIsB2B()

  if (!flags.length) {
    return null
  }

  return (
    <div data-testid="gallery-flags" className="absolute   z-[9] flex gap-2">
      {flags.slice(0, 2).map((flag: Flag, index: number) => {
        const isBalckFriday = flag?.value.toLowerCase() === 'black friday'

        if (isB2B && flag?.value === 'Oferta Clube Decathlon') {
          return null
        }

        return (
          <div
            key={`${index}`}
            className={`h-[19px] sm:h-[30px] flex gap-[4px] px-[6px] text-[10px] sm:text-xs items-center font-inter leading-[100%] sm:leading-[150%] ${
              isBalckFriday ? 'font-normal' : 'font-medium'
            } w-fit`}
            style={{ backgroundColor: flag?.color, color: flag?.textColor }}
          >
            {flag?.icon && (
              <img
                className="!w-[6px] sm:!w-[12px] h-auto"
                src={flag.icon}
                alt="Flag icon"
              />
            )}
            {flag?.value ?? ''}
          </div>
        )
      })}
    </div>
  )
}
