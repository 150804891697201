export function EletricBolt({ color = '#101010', width = 20, height = 20 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M5.95833 18.3538L9.52083 11.958L2.5 11.1247L12.8125 1.64551H14.0417L10.4792 8.04134L17.5 8.87467L7.1875 18.3538H5.95833Z"
        fill={color}
      />
    </svg>
  )
}
